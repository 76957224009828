import React from 'react';
import Layout from '../components/Layout';
import TitleAndTheme from '../components/TitleAndTheme';
import ProjectHeader from '../components/ProjectHeader';
import { GridThreeAcross } from '../components/Grid';
import AssetWithCaption from '../components/AssetWithCaption';
import Text from '../components/Text';

const Songstories = () => (
  <Layout>
    <TitleAndTheme
      title="Edwin Morris, Genius song stories"
      themeClass="theme--songstories"
    />

    <ProjectHeader
      name="Song Stories"
      subhead="Reimagining the song page"
      sectionid="songstories"
    />

    <Text>
      <p>
        One of the most popular songs on Genius, Rap God by Eminem, has 117
        annotations created by 1200+ contributors, a few verified annotations by
        Eminem, thousands of comments, a couple related articles and videos by
        Genius, 20+ question and answer pairs, and over 20 pieces of track info
        like recording location, samples, and the release date. The main product
        design challenge at Genius is fitting all of this information onto a
        page without taking away from the lyrics reading experience, and doing
        all of that on mobile.
      </p>
      <p>
        The song stories project reimagined the song page from the ground up,
        replacing every section and every interaction on the page. It began as a
        wholesale replacement for annotations on mobile, and evolved into a
        product integration pitch for streaming apps, similar to Spotify’s
        “Behind The Lyrics” integration with Genius.
      </p>
      <p>
        I led the project in both phases, and executed all the prototyping and
        animation. In the second phase I worked closely with other designers.
      </p>
    </Text>
    <Text noTopMargin>
      <h1>Phase 1</h1>
    </Text>

    <GridThreeAcross verticalMargin="bottom">
      <AssetWithCaption
        position="left_third"
        video="songstories/ss_cut1"
        width="722"
        height="1286"
      />

      <AssetWithCaption
        position="center_third"
        video="songstories/ss_cut2"
        width="722"
        height="1286"
      />

      <AssetWithCaption
        position="right_third"
        video="songstories/ss_cut3"
        width="722"
        height="1286"
      />
    </GridThreeAcross>

    <Text noTopMargin>
      <h1>Phase 2</h1>
    </Text>

    <GridThreeAcross>
      <AssetWithCaption
        position="left_third"
        video="songstories2/poll_small"
        width="728"
        height="1294"
      />

      <AssetWithCaption
        position="center_third"
        video="songstories2/youtube_activation_small"
        width="728"
        height="1294"
      />

      <AssetWithCaption
        position="right_third"
        video="songstories2/key_lyric_small"
        width="750"
        height="1334"
      />
    </GridThreeAcross>

    <Text>
      <p>
        In 2016, Spotify and Genius worked together to{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://genius.com/a/genius-and-spotify-together"
        >
          launch “Behind The Lyrics” in the Spotify app
        </a>{' '}
        — it was a huge success, and by the end of the year Genius was eyeing
        the story format as a way to expand on the idea, and integrate it into
        its own product.
      </p>
      <p>
        “Behind The Lyrics” is just text, and limited to the duration of a song,
        so a lot of interesting information gets omitted. Building a similar
        experience on the Genius platform meant no limits on content or media,
        leading to expanded capabilities like interactive features. For example,
        the editorial team wrote a fact track card comparing Black Beatles by
        Rae Sremmurd to Day Tripper by The Beatles — in the Song Story version,
        we added two interactive slides: an audio scrubber to crossfade between
        the compared tracks, and a poll to choose which band is best. Instagram
        Stories had just come out, and polls wouldn’t be introduced there until
        a year later.
      </p>
    </Text>

    <GridThreeAcross>
      <AssetWithCaption
        position="left_third"
        video="songstories/audio_slider"
        width="750"
        height="1334"
        hasSound
      />

      <AssetWithCaption
        position="center_third"
        video="songstories/poll"
        width="750"
        height="1334"
        hasSound
      />

      <AssetWithCaption
        position="right_third"
        video="songstories/image_slider"
        width="750"
        height="1334"
      />
    </GridThreeAcross>

    <Text>
      <p>
        Packaging annotations, comments, articles and videos into a separate
        experience from the lyrics meant we could show a single, strong call to
        action on the song page — the lyrics are unobstructed by annotations,
        and the value proposition of the Song Story is clear: “learn more about
        this song.”
      </p>
    </Text>

    <GridThreeAcross>
      <AssetWithCaption
        position="left_third"
        image="songstories/lyrics"
        width="1000"
        height="1779"
      />

      <AssetWithCaption
        position="center_third"
        video="songstories/mannequin_challenge"
        width="750"
        height="1334"
      />

      <AssetWithCaption
        position="right_third"
        video="songstories/rondo"
        width="750"
        height="1334"
      />
    </GridThreeAcross>

    <Text>
      <p>
        Before the design process began it was clear that static mockups
        wouldn’t be sufficient, since the product relies heavily on audio and
        video, and that the product would have to be reactive to editorial
        input. I used Origami to create individual interactions and animations,
        and eventually produced a fully-functioning demo app, fed by a JSON
        file.
      </p>
      <p>
        As the editorial team wrote content for Song Stories, I reacted with new
        templates and interactions that would best highlight it — this is where
        the polls, audio slider, and more came from. As I added these to the
        prototype, the editorial team could use it and send feedback and their
        own ideas back to me.
      </p>
      <p>
        Later on when we began pitching streaming apps with this concept, the
        fidelity of each screen became more impotent than the user experience,
        since it was being presented in decks during meetings, and not actually
        being used. I teamed up with another designer and focused on animations
        while they iterated on the visual design. Instead of Origami, I used
        After Effects to have complete control.
      </p>
    </Text>

    <GridThreeAcross>
      <AssetWithCaption
        position="left_third"
        video="songstories2/cta_video_chip_small"
        width="750"
        height="1334"
      />

      <AssetWithCaption
        position="center_third"
        video="songstories2/cta_thumb_peek_small"
        width="750"
        height="1334"
      />

      <AssetWithCaption
        position="right_third"
        video="songstories2/cta_text_rotate_small"
        width="750"
        height="1334"
      />
    </GridThreeAcross>

    <Text noBottomMargin>
      <p>
        This project was a great opportunity to learn more about prototyping,
        both the technical parts and how useful it is for collaboration and
        testing ideas.
      </p>
    </Text>
  </Layout>
);

export default Songstories;
